/**
 * "You generally should implement the same components in both
 *  gatsby-ssr.js and gatsby-browser.js so that pages generated
 *  through SSR are the same after being hydrated in the browser."
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { Provider as SvgUidProvider } from '@inline-svg-unique-id/react';
import { GlobalStyles } from './src/styles/GlobalStyles';
import './src/utils/polyfills';

export const wrapPageElement = ({ element, props }) => (
  <SvgUidProvider idPrefix="svg-uid-">
    <GlobalStyles />
    {element}
  </SvgUidProvider>
);

/**
 * See `navigateSafe` to use this.
 *
 * Pass true for the `disableScrollUpdate` param of that helper to maintain
 * scroll position after jump.
 *
 * For future reference: this Gatsby function can also jump to specific parts of the page
 *  https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
 */
export const shouldUpdateScroll = (props) => {
  return !props?.routerProps?.location?.state?.disableScrollUpdate;
};
