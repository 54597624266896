import Lottie from 'react-lottie-player';

/** Defaults for Lottie players with a11y ignored and autoplay disabled */
export const baseLottieOptions: Partial<typeof Lottie['defaultProps']> = {
  loop: false,
  play: false,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    progressiveLoad: true,
    title: '',
  },
  role: 'presentation',
  'aria-label': '',
  'aria-hidden': true,
} as const;
