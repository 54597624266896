import { NavItemData } from '.';

export const mainNavData: NavItemData[] = [
  {
    text: 'Solutions',
    to: '/solutions/',
    subnav: [
      {
        text: 'Rebate and Loyalty Programs',
        to: '/rebate-and-loyalty-programs/',
      },
      {
        text: 'Incentive and Reward Programs',
        to: '/incentive-and-reward-programs/',
      },
      {
        text: 'Co-op Advertising/MDF',
        to: '/coop-advertising-mdf/',
      },
      {
        text: 'Brand Compliance',
        to: '/brand-compliance/',
      },
    ],
  },
  {
    text: `Channel Marketing Technology`,
    to: '/channel-marketing-technology/',
  },
  {
    text: 'Resources',
    to: '/resources/',
  },
  {
    text: 'About',
    textMobile: 'About ACB',
    to: '/about-acb/',
    subnav: [
      {
        text: 'Careers',
        to: '/acb-careers/',
      },
    ],
  },
];

export const topNavCtaData: NavItemData = {
  text: 'Request Demo',
  to: '/request-a-demo/',
};

export const footerNavData = [
  ...mainNavData,
  {
    text: 'Contact Us',
    to: '/request-a-demo/#contact-us',
  },
];

export const videoCtaData: NavItemData = {
  text: 'Request a Demo Today',
  to: '/request-a-demo/',
};
