import { css, Global } from '@emotion/react';
import tw, { GlobalStyles as BaseStyles, theme } from 'twin.macro';
import './root.scss';

/**
 * Sets up global styles -- for :root variables please use root.scss
 */
const customStyles = css`
  body {
    font-family: ${theme`fontFamily.primary`};

    h1 {
      ${tw`text-4xl my-2 md:(text-6xl)`}
    }

    h2 {
      ${tw`text-4xl my-1 lg:(font-size[2.625rem] line-height[3.5rem])`}
    }

    h3 {
      ${tw`text-3xl my-0.5`}
    }

    h4 {
      ${tw`text-sm my-0.5 lg:(text-lg)`}
    }

    p {
      ${tw`text-base`}
    }

    h1,
    h2,
    h3,
    h4,
    p {
      /* trying to avoid messing with LinkPlus a styles, might not be the right way */
      a,
      a:visited {
        color: #242dfe;
      }
      a:hover {
        ${tw`underline`}
      }
    }

    hr {
      ${tw`w-full h-0.5 my-8 border-0 bg-gradient-to-r from-[#2A435E] to-[#50768C]`}
    }

    /* Use this setting for layout-intensive sites when font sizes are overridden by iOS */
    /* -webkit-text-size-adjust: none; */
  }

  /* Focus visible & polyfill */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /* Tab focus style */
  button:focus,
  input:focus,
  a:focus {
    outline: gray dashed 1px;
  }

  /* Current workaround to disable GastbyImage fades 
      see: https://github.com/gatsbyjs/gatsby/discussions/30699?sort=new#discussioncomment-1019782 */
  .gatsby-image-wrapper [data-main-image] {
    transition: none;
    opacity: 1;
  }
  /* Fade out modals */
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

export const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
);
