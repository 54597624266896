import 'focus-visible/dist/focus-visible.min';
// adds IntersectionObserver support for Safari < 12.2
import 'intersection-observer';
import smoothscroll from 'smoothscroll-polyfill';
import { isSSG } from '~/config';

/* 
We removed async loading to avoid race conditions.

Each polyfill (besides focus-visible which is really a js utility) needs
to defert to newer native functionality if it exists:
- https://github.com/iamdustan/smoothscroll/blob/master/src/smoothscroll.js#L9 
- https://github.com/w3c/IntersectionObserver/blob/main/polyfill/intersection-observer.js#L17-L18
*/

// adds smooth scrolling support for Safari, mobile Safari, IE, and Edge
// (used in DragScroller but not for page transitions)
if (!isSSG) {
  smoothscroll.polyfill();
}
