import {
  getImage as getImageGatsby,
  IGatsbyImageData
} from 'gatsby-plugin-image';
import { IconName } from '~/components/atoms/Icon';
import { BenchmarkProps } from '~/components/molecules/Benchmark';
import { ButtonFlavor } from '~/components/molecules/buttons';
import { ImageLineupProps } from '~/components/molecules/ImageLineup';
import { BackPageSectionKind } from '~/components/organisms/BackPageSection';
import { PartnersSectionKind } from '~/components/organisms/PartnersSection';
import {
  ColorWheelFlavor,
  IconFlavor,
  Orientation,
  Position,
  SectionTheme
} from '~/components/shared';
import { SectionSpacing } from '~/components/wrappers/SectionWrapper';
import { Globals, SiteMetadata } from '~/context/GlobalsContext';
import { isSSG } from './consts';

const isSectionType = (data, type: string) =>
  typeof data === 'object' && data['type'] === type;

/**
 * CMS Data Types and type-guard functions.
 *
 * Import the namespace and use like: `CMSData.isSideBySide(data)`
 */
export namespace CMSData {
  // type guard functions
  export const is = {
    pageBase: (data): data is PageBase =>
      typeof data === 'object' &&
      data.hasOwnProperty('slug') &&
      data.hasOwnProperty('components'),

    // alphabetical order
    backPageSection: (data): data is BackPageSection =>
      isSectionType(data, 'back_page_section'),
    benchmark: (data): data is BenchmarkSection =>
      isSectionType(data, 'benchmark_section'),
    benchmarkPoster: (data): data is BenchmarkPoster =>
      isSectionType(data, 'benchmark_poster'),
    calloutCardSection: (data): data is CalloutCardSection =>
      isSectionType(data, 'callout_card_section'),
    carouselSection: (data): data is CarouselSection =>
      isSectionType(data, 'carousel_section'),
    centeredSection: (data): data is CenteredSection =>
      isSectionType(data, 'centered_section') ||
      isSectionType(data, 'centered_text_section'),
    cycleCardSection: (data): data is CycleCardSection =>
      isSectionType(data, 'cycle_card_section'),
    demoSection: (data): data is RequestDemoSection =>
      isSectionType(data, 'request_demo_section'),
    hero: (data): data is HeroSection => isSectionType(data, 'hero_section'),
    heroWithCarousel: (data): data is HeroWCarouselSection =>
      isSectionType(data, 'hero_section_w_carousel'),
    historySection: (data): data is HistorySection =>
      isSectionType(data, 'history_section'),
    iconGrid: (data): data is IconGrid => isSectionType(data, 'icon_grid'),
    imageLineupSection: (data): data is ImageLineupSection =>
      isSectionType(data, 'lineup_section'),
    infoSection: (data): data is InfoSection =>
      isSectionType(data, 'info_section'),
    leadershipSection: (data): data is LeadershipSection =>
      isSectionType(data, 'leadership_section'),
    locationsSection: (data): data is LocationsSection =>
      isSectionType(data, 'locations_section'),
    opportunitiesSection: (
      data,
      silent = false,
    ): data is OpportunitiesSection =>
      isSectionType(data, 'opportunities_section'),
    newsBanner: (data): data is NewsBanner =>
      isSectionType(data, 'news_banner'),

    partnersSection: (data): data is PartnersSection =>
      isSectionType(data, 'partners_section'),
    posterPosterSection: (data): data is PosterPosterSection =>
      isSectionType(data, 'poster_poster_section'),
    resourceSection: (data): data is ResourceSection =>
      isSectionType(data, 'resource_section'),
    sideBySide: (data): data is SideBySideSection =>
      isSectionType(data, 'side_by_side'),
    tabbedSection: (data): data is TabbedSection =>
      isSectionType(data, 'tabbed_section'),
    technology: (data): data is TechnologySection =>
      isSectionType(data, 'technology_section'),
  };

  /**
   * Convenience function to retrieve image data from CMS Image
   * Use this instead of the one in `gatsby-plugin-image`
   * @param i Any `Image`-shaped CMS object
   * @returns Gatsby image data, or the file path (for SVGs)
   */
  export const getImage = (i: Image | string) => {
    // this should never be string from CMS - included for stories using getComponentData
    if (typeof i === 'string') {
      return i;
    }

    if (i?.childImageSharp) {
      return getImageGatsby(i.childImageSharp.gatsbyImageData)!;
    }

    // Use the path when the image was not processed by sharp (eg SVG)
    if (i?.publicURL) {
      return i.publicURL;
    }

    if (isSSG) {
      console.error('getImage failure', i);
    }
    return ''; // TODO
  };

  // Props should be named here *as they exist in the data*
  // That means snake_case, with the exception of image props
  // (Because that is how they exist in the queries)w

  export type PageBase = {
    slug: string;
    title?: string;
    description?: string;
    keywords?: string;
    components: SectionBase[];
    resource?: {
      heading: string;
      download: ResourceDownload;
    };
    resourceSections?: ResourceSection[];
    og_image?: Image;
    globals?: Globals;
    siteMetadata?: SiteMetadata;
  };

  export type SectionBase = {
    type: string;
    theme: SectionTheme; // all sections should implement theme, even if just as a hidden field
    hidden?: boolean; // any section can include this for basic draft-mode functionality
    [key: string]: unknown;
  };

  // -- Hero Section --

  export type HeroWCarouselSection = HeroSection &
    SectionBase & {
      mini_carousel_icon: Image;
      mini_carousel_text_list: { message: string }[];
    };

  export type HeroSection = SectionBase & {
    theme: SectionTheme;
    eyebrow?: string;
    heading: string;
    primary_text: string;
    secondary_text?: string;
    cta?: Cta;
    image_list: ImageList;
  };

  // -- Benchmark Section --

  export type BenchmarkSection = SectionBase & {
    benchmark_list: BenchmarkProps[];
  };

  // -- Side By Side --

  export type SideBySideSection = SectionBase & {
    layout: Orientation;
    image_w_color_wheel: ImageWithColorWheel;
    content_block_w_icon: ContentBlockWithIcon;
  };

  // -- Centered Section --

  export type CenteredSection = SectionBase & {
    image_w_color_wheel: ImageWithColorWheel;
    content_block: ContentBlock;
    spacing?: SectionSpacing;
  };

  // -- Partners Section --

  export type PartnersSection = SectionBase & {
    hidden: boolean;
    heading: string;
    kind: PartnersSectionKind;
    image_list_with_links: ImageListWithLinks;
  };
  // -- BackPage Section --

  export type BackPageSection = SectionBase & {
    heading: string;
    hidden: boolean;
    kind: BackPageSectionKind;
  };

  // -- Centered Poster --

  export type BenchmarkPoster = ColorWheel &
    SectionBase & {
      primary_text: string;
      header: string;
      info: string;
      cta: Cta;
      quotes: { message: string }[];
      position: Position;
    };

  // -- Technology --

  export type TechnologySection = ContentBlock & SectionBase;

  // -- Cycle Section --

  type CycleCardLottie = {
    extension: 'json';
    publicURL: string;
    internal?: {
      content: string;
    };
  };

  export type CycleCard = SectionBase & {
    heading: string;
    primary_text: string;
    list_header: string;
    list_content: string;
    image_w_alt: ImageWithAlt;
    lottie?: CycleCardLottie;
  };

  export type CycleCardSection = SectionBase & {
    heading: string;
    secondary_text: string;
    cycle_card_list: CycleCard[];
  };

  // -- Callout Card Section --

  export type CalloutCard = SectionBase & {
    heading: string;
    primary_text: string;
    icon: IconName;
    icon_flavor: IconFlavor;
    cta: Cta;
  };

  export type CalloutCardSection = SectionBase & {
    heading: string;
    image_w_alt: ImageWithAlt;
    callout_card_list: CalloutCard[];
  };

  // -- Poster Poster Section --

  export type PosterPosterSection = SectionBase & {
    heading: string;
    primary_text: string;
    image_list: ImageList;
    eyebrow: boolean;
  };

  // -- Icon Grid Section --

  export type IconGrid = SectionBase & {
    heading: string;
    primary_text?: string;
    use_small_heading: boolean;
    icon_flavor: IconFlavor;
    icon_list: {
      icon: IconName;
      label?: string;
      text?: string;
    }[];
  };

  // -- Carousels --
  export type CarouselItem = {
    heading: string;
    secondary_text: string;
    image_w_alt: ImageWithAlt;
  };

  export type CarouselSection = ColorWheel &
    SectionBase & {
      content_block: ContentBlock;
      carousel: CarouselItem[];
    };

  export type HistorySection = SectionBase & {
    content_block: ContentBlock;
    tooltip: string;
    carousel: CarouselItem[];
  };

  export type RequestDemoSection = SectionBase & {
    image_w_color_wheel: ImageWithColorWheel;
    heading: string;
    primary_text: string;
    secondary_text: string;
    submit_error_message: string;
    thanks_heading: string;
    thanks_primary_text: string;
  };

  // -- Resources --

  type ResourceDownload = {
    extension: 'pdf'; // Others TK?
    publicURL: string;
  };

  export type Resource = {
    heading: string;
    secondary_text: string;
    image_w_alt: ImageWithAlt;
    download: ResourceDownload;
    resource_slug: string;
    featured?: boolean;
    new_before: number;
  };

  export type ResourceSection = SectionBase & {
    slug: string;
    heading: string;
    tooltip: string;
    resources: Resource[];
  };

  // -- Info Section --

  export type InfoSection = SectionBase & {
    heading: string;
    primary_text: string;
    image_list: ImageList | [];
    info_block_list: { info_block_w_icon: InfoBlockWIcon }[];
  };

  // -- Image Lineup Section --

  export type ImageLineupSection = ColorWheel &
    SectionBase & {
      heading: string;
      primary_text: string;
      image_list: ImageList | [];
      alignment: ImageLineupProps['alignment'];
    };

  // -- Leadership Section --

  export type Leader = {
    heading: string;
    secondary_text: string;
    bio?: string;
    image?: Image;
    link?: string;
  };

  export type LeadershipSection = SectionBase & {
    heading: string;
    primary_text: string;
    leadership_list: Leader[];
  };

  // -- Tabbed Section --

  export type TabCard = {
    icon: IconName;
    heading: string;
    primary_text: string;
    list_header?: string;
    list_content: string;
    image_w_alt: ImageWithAlt;
  };

  export type TabbedSection = SectionBase & {
    heading: string;
    primary_text: string;
    tab_cards: TabCard[];
  };

  // -- Locations Section --

  export type LocationsSection = SectionBase & {
    heading: string;
    primary_text: string;
  };

  // -- Opportunities Section --

  export type OpportunitiesSection = SectionBase & {
    heading: string;
    tooltip: string;
    opportunities: {
      image_w_alt: ImageWithAlt;
      link: string;
      location: string;
    }[];
  };

  // -- Supporting types

  export type Cta = {
    text: string;
    link: string;
    flavor: ButtonFlavor;
  };

  export type ContentBlock = {
    heading: string;
    primary_text: string;
    secondary_text?: string;
    cta?: Cta;
  };

  export type ContentBlockWithIcon = {
    icon: IconName;
    icon_flavor: IconFlavor;
    content_block: ContentBlock;
  };

  export type InfoBlockWIcon = {
    icon: string;
    heading: string;
    primary_text: string;
  };

  type Image = {
    extension: 'svg' | 'png' | 'jpg';
    publicURL: string;
    childImageSharp?: {
      gatsbyImageData: IGatsbyImageData;
    };
  };

  export type ImageWithAlt = {
    // this is always Image from the CMS, string is included for stories using getComponentData
    image: Image | string;
    image_alt?: string; // Added after launch, can be progressively added to components as needed
    image_caption?: string;
  };

  export type ImageWithAltOptional = {
    // this is always Image from the CMS, string is included for stories using getComponentData
    image?: Image | string;
    image_alt?: string;
  };

  export type NewsBanner = {
    news_title: string;
    news_heading?: string;
    news_text?: string;
    image_w_alt_optional?: ImageWithAltOptional;
    link?: string;
    show_until: number;
  };

  export type ImageList = {
    image_w_alt: ImageWithAlt;
  }[];

  export type ImageListWithLinks = {
    image_w_alt: ImageWithAlt;
    link?: string;
  }[];

  export type ImageWithColorWheel = ImageWithAlt &
    ColorWheel & {
      small_heading?: string;
      medium_heading?: string;
      large_heading?: string;
    };

  export type ColorWheel = {
    flavor: ColorWheelFlavor;
  };
}
